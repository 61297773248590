<template>
  <v-card flat>
    <v-data-table
      dense
      :headers="headers"
      :items="membersFormated"
      :items-per-page="10">
      <template v-slot:top>
        <v-toolbar
          flat
          dense>
          <v-spacer></v-spacer>
          <v-dialog
            persistent
            v-model="dialogAdd">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                >ADD</v-btn
              >
            </template>
            <add-members
              @close="dialogAdd = false"
              :idCluster="id" />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          dense
          class="mr-2"
          @click="infoItem(item)">
          mdi-information
        </v-icon>
        -
        <v-icon
          small
          :color="item.user.password ? 'success' : 'error'"
          @click="loginToUser(item.user.id)"
          :disabled="loading">
          mdi-key
        </v-icon>
        -
        <v-icon
          small
          :color="item.user.password ? 'success' : 'error'"
          @click="loginToUserV3(item.user.id)"
          :disabled="loading">
          mdi-numeric-3-box
        </v-icon>
        -
        <v-icon
          small
          :color="item.user.password ? 'success' : 'error'"
          @click="loginToUserV3Preprod(item.user.id)"
          :disabled="loading">
          mdi-beta
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogInfo"
      max-width="550px">
      <v-card>
        <v-card-title class="text-h5">{{ infoSelected.name }}</v-card-title>
        <v-simple-table
          dense
          v-if="infoSelected.rules">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="keyField in Object.keys(infoSelected.rules)"
                :key="keyField">
                <td>{{ keyField }}</td>
                <td class="text-right">
                  <v-icon
                    v-if="infoSelected.rules[keyField]"
                    color="success"
                    dense
                    >mdi-thumb-up</v-icon
                  >
                  <v-icon
                    v-else
                    color="error"
                    dense
                    >mdi-thumb-down</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeInfos"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import addMembers from '../../components/Clusters/addMembers.vue'
import usersLoginCodeServices from '@/services/usersLoginCode.services'

export default {
  components: { addMembers },
  props: ['id'],
  data() {
    return {
      infoSelected: {},
      dialogInfo: false,
      dialogAdd: false,
      loading: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'user.email' },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
    }
  },
  mounted() {
    this.getMembersClusters(this.id)
  },
  watch: {
    id(val) {
      if (val) {
        this.getMembersClusters(this.id)
      }
    },
  },
  computed: {
    ...mapGetters(['members']),
    membersFormated() {
      return this.members.map((elem) => ({
        ...elem,
        name: [elem.user.firstname, elem.user.lastname].filter(Boolean).join(' '),
      }))
    },
  },
  methods: {
    ...mapActions(['getMembersClusters']),
    infoItem(item) {
      this.infoSelected = { ...item }
      this.dialogInfo = true
    },
    closeInfos() {
      this.infoSelected = {}
      this.dialogInfo = false
    },
    async loginToUser(idUser) {
      this.loading = true
      const { data } = await usersLoginCodeServices.getUserLoginCode(idUser)
      window.open(`https://claude.samsys.io/loginCode?code=${data.code}`, '_blank').focus()
      this.loading = false
    },
    async loginToUserV3(idUser) {
      this.loading = true
      const { data } = await usersLoginCodeServices.getUserLoginCodeV3(idUser)
      window.open(`https://my.samsys.io/loginCode?code=${data.code}`, '_blank').focus()
      this.loading = false
    },
    async loginToUserV3Preprod(idUser) {
      this.loading = true
      const { data } = await usersLoginCodeServices.getUserLoginCodeV3(idUser)
      window.open(`https://my.samsys.ovh/loginCode?code=${data.code}`, '_blank').focus()
      this.loading = false
    },
  },
}
</script>
